import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import ProjectGallery from "./ProjectGallery"
// import HomeNav from "./homeNav"

//COMPONENT

const Project = ({ data }) => {
  //Info State

  const content = data.content.replace("<p>", "").replace("</p>", "")

  const titleContent = `<h1>${data.title}</h1> ${content}`

  return (
    <ProjectWrapper>
      <MenuButton>
        <Link to="/"></Link>
      </MenuButton>
      <ProjectGallery media={data.acf.media_projects} />
      <ProjectInfo>
        <div dangerouslySetInnerHTML={{ __html: titleContent }} />
      </ProjectInfo>
    </ProjectWrapper>
  )
}

export default Project

//STYLEZ

const MenuButton = styled.div`
  position: fixed;
  top: 2rem;
  left: 2rem;
  z-index: 100;
  display: block;
  background: black;
  width: 10px;
  height: 10px;
  border: none;
  outline: none;
  /* text-indent: -9999px; */
  padding: 0;
  cursor: pointer;
  border-radius: 100%;
  background: red;
  a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }
  &:hover {
    background: black;
  }
  ${props =>
    props.open &&
    `
    background: white;
  `}

  @media only screen and (max-width: 500px) {
    top: 2rem;
    left: 1rem;
  }
`

const ProjectWrapper = styled.div`
  background: white;
  width: 100%;
  /* height: 100vh; */
  ${props =>
    props.blur &&
    `
    background: black;
  `}
`

const ProjectInfo = styled.div`
  bottom: 0rem;
  left: 0rem;
  position: fixed;
  /* background: white; */
  width: 100%;
  padding: 2rem;
  padding-bottom: 2rem;
  font-size: 0.75rem;
  letter-spacing: 0.04rem;
  h1 {
    font-size: 0.75rem;
    font-weight: normal;
    margin: 0;
    font-style: italic;
    display: inline;
  }

  @media only screen and (max-width: 500px) {
    h1 {
      font-size: 0.75rem;
    }
    padding: 1rem;
    font-size: 0.75rem;
    width: 100%;
  }
`
