import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Project from "../components/Project"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title={data.wordpressWpProjects.title}
      keywords={[`gatsby`, `application`, `react`]}
    />
    <Project
      projects={data.allWordpressWpProjects.edges}
      data={data.wordpressWpProjects}
    />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query($slug: String!) {
    wordpressWpProjects(slug: { eq: $slug }) {
      title
      content
      acf {
        media_projects {
          __typename
          ... on WordPressAcf_image {
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          __typename
          ... on WordPressAcf_gallery {
            gallery {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          __typename
          ... on WordPressAcf_video {
            video
          }
        }
      }
    }
    allWordpressWpProjects(filter: { wordpress_parent: { eq: 0 } }) {
      edges {
        node {
          wordpress_id
          slug
          title
          wordpress_parent
          project_children {
            post_title
            post_name
          }
        }
      }
    }
  }
`
