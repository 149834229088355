import React, { Component } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"

const HomeSlider = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  overflow: hidden;
  .slick-arrow {
    position: absolute;
    transform: translate(0, -50%);
    top: 50%;
    text-indent: -9999px;
    background: black;
    width: 10px;
    height: 10px;
    border: none;
    outline: none;
    border-radius: 100%;
    padding: 0;
    cursor: pointer;
    z-index: 100;
    &.slick-next {
      right: 2rem;
      @media only screen and (max-width: 500px) {
        right: 1rem;
      }
    }
    &.slick-prev {
      left: 2rem;
      @media only screen and (max-width: 500px) {
        left: 1rem;
      }
    }
    &:hover {
      background: red;
    }
  }
  ${props =>
    props.blur &&
    `
    filter: blur(0.5rem);
    opacity: 0.5;
  `}
`

const Slide = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const VideoContainer = styled.div`
  width: 68%;
  position: relative;
  > div {
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`

class ProjectGallery extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      pauseOnHover: false,
    }

    let media = []

    if (this.props.media) {
      this.props.media.forEach((e, i) => {
        if (e.__typename === "WordPressAcf_image") {
          media.push(
            <ImageSlide
              key={i}
              image={e.image.localFile.childImageSharp.fluid}
            />
          )
        }
        if (e.__typename === "WordPressAcf_gallery" && e.gallery) {
          e.gallery.forEach(i => {
            if (i.localFile) {
              media.push(
                <ImageSlide key={i} image={i.localFile.childImageSharp.fluid} />
              )
            }
          })
        }
        if (e.__typename === "WordPressAcf_video") {
          media.push(<VideoSlide key={i} video={e.video} />)
        }
      })
    }

    return (
      <div>
        <HomeSlider blur={this.props.blur}>
          <Slider {...settings}>{media}</Slider>
        </HomeSlider>
      </div>
    )
  }
}

const ImageSlide = props => {
  return (
    <>
      <div>
        <Slide>
          <Img
            style={{ maxHeight: "75%", width: "75%" }}
            imgStyle={{ objectFit: "contain" }}
            fluid={props.image}
          />
        </Slide>
      </div>
    </>
  )
}

const VideoSlide = props => {
  return (
    <>
      <div>
        <Slide>
          <VideoContainer>
            <div dangerouslySetInnerHTML={{ __html: props.video }} />
          </VideoContainer>
        </Slide>
      </div>
    </>
  )
}

export default ProjectGallery
